import { useState } from "react";
import useGameStore from "../../lib/store/GameStore";
import { BaseDialog } from "./BaseDialog";

function PromoDialog({ modalTitle = "Introducing Draft Phantom!" }) {
  const [isOpen, setIsOpen] = useState(true);

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <>
      <BaseDialog
        isOpen={isOpen}
        closeModal={closeModal}
        modalTitle={modalTitle}
        modalBody={<HowToPlayBody />}
        size="md"
      />
    </>
  );
}

function HowToPlayBody() {
  return (
    <div className="my-2 space-y-4 px-2 pt-2 text-md sm:px-4">
      <div>
        Get into the AFL Draft action with{" "}
        <a
          className="font-medium text-blue-600 hover:underline"
          href="https://draftphantom.com/?utm_source=gridley&utm_medium=affiliate&utm_campaign=modal"
          target="_blank"
        >
          Draft Phantom
        </a>
        , the all-new Predictor game by BikeGrid.
      </div>

      <div className="flex h-40 items-center overflow-hidden sm:h-72">
        <img src="https://pbs.twimg.com/media/F_R31Ymb0AAQ1t3?format=jpg&name=medium" />
      </div>
      <div className="pl-0">
        <ul>
          <li>✅ Make your picks</li>
          <li>💯 Earn points based on accuracy</li>
          <li>📣 Share your draft card with friends</li>
        </ul>
      </div>
      <div>
        Predictions close at 7pm Monday AEDT, so{" "}
        <a
          className="font-medium text-blue-600 hover:underline"
          href="https://draftphantom.com/?utm_source=gridley&utm_medium=affiliate&utm_campaign=modal"
          target="_blank"
        >
          complete your draft card now!
        </a>
      </div>
    </div>
  );
}

function HelpListItem({
  bgColor = "",
  textColor = "",
  highlightText = "",
  afterText,
}) {
  return (
    <li>
      {highlightText !== "" && (
        <span
          className={`${bgColor} ${textColor} -m-0.5 inline-block rounded-sm px-1 py-0.5 font-medium`}
        >
          {highlightText}
        </span>
      )}{" "}
      {afterText}
    </li>
  );
}

export default PromoDialog;
